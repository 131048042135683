body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6;
}

.login-box {
  margin: 100px auto;
  background-color: #fff;
  width: 50%;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 5px #ccc;
}

.login-title {
  font-weight: 200;
  font-size: 3em;
  text-align: center;
  color: #333;
}

.input-label {
  margin: 20px 0 5px 0;
  font-size: 1.8em;
  color: #333;
}

.input-field {
  width: calc(100% - 20px);
  padding: 10px;
  border: 0;
  box-shadow: 0 0 3px #aaa;
  font-family: inherit;
  font-size: 1.2em;
  color: #555;
  border-radius: 5px;
}
*:focus {
  outline: none !important;
}

.select-field {
  width: 100%;
  padding: 10px;
  border: 0;
  box-shadow: 0 0 3px #aaa;
  font-family: inherit;
  font-size: 1.2em;
  color: #555;
  border-radius: 5px;
}

.submit-button {
  width: 100%;
  padding: 10px;
  margin-top: 40px;
  border: 0;
  box-shadow: 0 0 3px #aaa;
  font-family: inherit;
  font-size: 1.2em;
  background-color: #fff;
  color: #555;
  border-radius: 5px;
}

.submit-button:hover {
  cursor: pointer;
  background-color: #eee;
}

.submit-button:disabled {
  border: 1px solid #999999;
  background-color: #999;
  color: #666666;
}

nav {
  height: 50px;
  background-color: #ff5f5d;
  text-align: right;
}

nav > button {
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  color: #fff;
  font-family: inherit;
  font-size: 1.2em;
  cursor: pointer;
  margin: 0 20px;
  padding: 0 15px;
}

nav > button:hover {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
}

h1 {
  font-size: 2.5em;
}

.task-container {
  padding: 12px 10vw 12px 10vw;
}

.new-task-box {
  width: 90%;
  margin: 10px auto;
  background-color: #fff;
  border-radius: 5px;
  min-height: 100px;
  padding: 5px;
}

.new-task-box .input-label {
  font-size: 1.2em;
}

.new-task-box > h2 {
  margin: 10px 15px;
}

.flex-row {
  flex-wrap: wrap;
  display: flex;
  gap: 0 20px;
  margin: 10px;
}

.flex-item {
  flex-grow: 1;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.task-card {
  width: calc(90% - 20px);
  margin: 15px auto;
  padding: 15px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.task-header {
  background-color: #eee;
}

.task-status button {
  padding: 10px;
  border: 0;
  box-shadow: 0 0 3px #777;
  font-family: inherit;
  font-size: 1em;
  background-color: rgba(240, 240, 240, 0.2);
  color: #333;
  border-radius: 5px;
  margin-right: 10px;
}

.task-status button:hover {
  cursor: pointer;
  background-color: rgba(220, 220, 220, 0.5);
}

.task-name-description {
  flex-grow: 6;
}

.task-name {
  font-size: 1.2em;
  font-weight: 200;
}

.task-description {
  font-size: 0.8em;
}

.task-author {
  flex-grow: 1;
  font-size: 0.8em;
}

.task-priority {
  flex-grow: 1;
  font-size: 0.8em;
}

.task-dueDate {
  flex-grow: 1;
  font-size: 0.8em;
}

.comlete {
  background-color: #00ccbf;
  color: #666;
}

.tasks-container {
  padding: 15px;
}

.NavLink {
  color: #fff;
  font-size: 1.2em;
  text-decoration: none;
  margin-left: 10px;
  margin-right: 10px;
  color: #fff;
}

.navLinkMenu {
  color: #333;
  text-decoration: none;
  font-size: 12;
}

